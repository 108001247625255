// @ts-ignore
import Headroom from 'headroom.js';

export default function init() {
  const headerEl = document.querySelector('header');
  if (headerEl) {
    const hr = new Headroom(document.querySelector('header'), { offset: 80 });
    hr.init();
  }
}
