// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

export default function init() {
  // Docs: https://github.com/biati-digital/glightbox
  GLightbox({
    selector: '.glightbox',
    plyr: {
      config: {
        ratio: '16:9',
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          modestbranding: 1,
          iv_load_policy: 3,
        },
        vimeo: {
          byline: false,
          portrait: false,
          title: false,
          speed: true,
          transparent: false,
        },
      },
    },
  });
  // @ts-ignore
  window.lightbox = GLightbox();
  // @ts-ignore
  window.GLightbox = GLightbox;
}
