// Import our CSS
import '@/css/app.css';

import importAsync from './utils/importAsync';
import alpinejs from './global/alpinejs';
import Lightbox from './global/Lightbox';
import Header from './global/Header';

const init = async function () {
  Lightbox();
  Header();

  const Alpine = alpinejs.setup();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('homepageHero', () => import('./components/homepageHero'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('clientResults', () => import('./components/clientResults'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('additionalServiceDetailCards', () => import('./components/additionalServiceDetailCards'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('testimonialSectionVideo', () => import('./components/testimonialSectionVideo'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('testimonialSectionImage', () => import('./components/testimonialSectionImage'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('podcastPlayer', () => import('./components/podcastPlayer'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('tabbedServiceDetails', () => import('./components/tabbedServiceDetails'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('mainNav', () => import('./components/mainNav'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('dialog', () => import('./components/dialog'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('algoliaSearch', () => import('./components/algoliaSearch'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('heroTitleUnderline', () => import('./components/heroTitleUnderline'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('imageContentMirrorSteps', () => import('./components/imageContentMirrorSteps'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('horizontalCarouselSwiper', () => import('./components/horizontalCarouselSwiper'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('chapterTable', () => import('./components/chapterTable'));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Alpine.asyncData('tabbedShowcase', () => import('./components/tabbedShowcase'));

  Alpine.start();

  importAsync('[data-plyr]', 'plyr', true, true, true);
};

init().then(() => {
  console.log('Done!');
});
